import axios from 'axios'
import { ElMessage,ElMessageBox } from 'element-plus'
import store from '../store'
import Cookies from 'js-cookie'
// import cookie from "js-cookie"
const instance = axios.create({
  baseURL: "http://13.114.63.198:9119",
  timeout: 10000
})


instance.interceptors.request.use(
  config =>{
    let userInfo = Cookies.get('userInfo')
    if(userInfo) {
      userInfo = JSON.parse(userInfo)
      config.headers['token'] = userInfo.token
    }
    return config
  },error =>{
    console.log(error)
    Promise.reject(error)
})


instance.interceptors.response.use(response =>{
  const res = response.data
  // if(res.code === 20000) {
  //   return response.data
  // } else if(res.code === 23004) {
  //   cookie.set('finger_jwt_token','',{domain: 'localhost'})
  //   return response.data
  // }

  if(res.code !== 20000) {
    ElMessage({
      message: res.message,
      type: 'error',
      duration: 5 * 1000
    })
    // 50008:非法token 50023: 已经登陆 50014:token过期   
    if(res.code === 50008 || res.code === 50012 || res.code === 50014) {
      ElMessageBox.confirm(
        '已经登出，可以取消继续停留，或者重新登陆',
        '确定登出',
        {
          confirmButtonText:'重新登陆',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(()=>{
        store.dispatch('FedLogOut').then(()=>{
          location.reload() //重新实例化router 避免bug
        })
      })
    } else if(res.code == 28006) {
      window.location.href = '/login'
    } else if(res.code == 28019) {
      window.location.href = '/locked'
    } else if(res.code == 222) {
      window.location.href = '/twoStepLogin'
    } 
    return Promise.reject('error')
  } else{
    return response.data
  }
},error =>{
    console.log(error)
    ElMessage({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject('error')
  }
)
export default instance;
// return instance(config)

